import React from "react";
import parse from "html-react-parser";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Modules from "../components/Modules";
import Hero from "../components/Hero";
import AlertBanner from "../components/AlertBanner";

export default function Index({ data }) {
  const entry = data.craft.entries[0];
  const modules = data.craft.entries[0].modules;
  return (
    <Layout heroVariation={entry.heroVariation}>
      <SEO>
        {parse(data.craft.seomatic.metaTitleContainer)}
        {parse(data.craft.seomatic.metaTagContainer)}
        {parse(data.craft.seomatic.metaLinkContainer)}
        {parse(JSON.parse(data.craft.seomatic.metaScriptContainer).script)}
        {parse(data.craft.seomatic.metaJsonLdContainer)}
      </SEO>
      <Hero
        heroVariation={entry.heroVariation}
        heroButtonText={entry.heroButtonText}
        heroInternalButtonUrl={entry.heroInternalButtonUrl}
        heroExternalButtonUrl={entry.heroExternalButtonUrl}
        // Passing VideoPlaceHolderImage here for homepage only
        heroBackgroundImage={entry.heroBackgroundVideoPlaceholderImage}
        heroBackgroundVideo={entry.heroBackgroundVideo}
        heroHeading={entry.heroHeading}
        heroSubheading={entry.heroSubheading}
        heroButtonType={entry.heroButtonType}
        heroBottomSpacing={entry.heroBottomSpacing}
        heroShowDarkVariant={entry.heroShowDarkVariant}
      />
      {modules && <Modules modules={modules} />}
      <AlertBanner />
    </Layout>
  );
}

export const query = graphql`
  query {
    craft {
      entries(type: "homepage") {
        ... on Craft_homepage_homepage_Entry {
          id
          title
          modules {
            ...textBlockData
            ...imageGalleryData
            ...fullwidthImageData
            ...imageGridData
            ...teamMemberGridData
            ...blogCardGridData
            ...ctaCardData
            ...imageSliderData
            ...logoGridData
            ...workCardData
            ...rollupCtaData
            ...selectableWorkCardsData
            ...imageWithContentData
            ...videoData
            ...hubspotEmbedData
            ...twoColumnTextBlockData
            ...timelineData
            ...imageData
          }
          heroShowDarkVariant
          heroBackgroundVideoPlaceholderImage {
            ... on Craft_uploads_Asset {
              id
              xLarge: url(width: 1600, position: "centerCenter")
              large: url(width: 1024, position: "centerCenter")
              medium: url(width: 700, position: "centerCenter")
              small: url(width: 500, position: "centerCenter")
              xLarge_2x: url(width: 3200, position: "centerCenter")
              large_2x: url(width: 2052, position: "centerCenter")
              medium_2x: url(width: 1400, position: "centerCenter")
              small_2x: url(width: 1000, position: "centerCenter")
              altText
            }
          }
          heroBackgroundVideo {
            ... on Craft_uploads_Asset {
              id
              url
            }
          }
          heroVariation
          heroButtonText
          heroButtonType
          heroInternalButtonUrl {
            uri
          }
          heroExternalButtonUrl
          heroHeading
          heroSubheading
          heroBottomSpacing
        }
      }

      seomatic(uri: "/") {
        metaTitleContainer
        metaTagContainer
        metaLinkContainer
        metaScriptContainer
        metaJsonLdContainer
      }
    }
  }
`;
