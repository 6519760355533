import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styles from "./index.module.css";
import Close from "../../images/close.svg";

export default function AlertBanner() {
  const data = useStaticQuery(graphql`
    {
      craft {
        globalSets(handle: "alert")  {
          ... on Craft_alert_GlobalSet {
            showAlert
            alertText
          }
        }
      }
    }
  `);

  const alertData = data.craft.globalSets[0];

  const [isVisible, setExpanded] = useState(false);

  return (
    alertData.showAlert && (
      <div
        className={`
        ${styles.AlertBanner}
        ${isVisible ? "hidden" : ""}
      `}
      >
        <div className={`${"container container--752px"}`}>
          <span
            dangerouslySetInnerHTML={{ __html: alertData.alertText }}
          ></span>

          <button
            onClick={() => setExpanded(!isVisible)}
            type="button"
            aria-label="Close Alert"
          >
            <Close />
          </button>
        </div>
      </div>
    )
  )
}
